import { useState } from "react";
import MainLayout from "../../layouts"
import Paper from '@mui/material/Paper';
import { Alert, Box, Grid, Snackbar } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from "react";
import { getTiers, getCustomers, getTiersByCustomer } from "../../utils/api"
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import "./tiers.scss"
import EditionModal from "../../components/Tiers/EditionModal";
import Select from "../../components/Select"


function TiersPage() {
    const [vehicles, setVehicles] = useState([])
    const [tiers, setTiers] = useState([])
    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState("")
    const [editionModalOpen, setEditionModalOpen] = useState(false)
    const [selectedTier, setSelectedTier] = useState({})
    const [openAlert, setOpenAlert] = useState(false)
    const [alertData, setAlertData] = useState({ message: "", type: "", })

    const columns = [
        { field: 'customer', headerName: 'Cliente', flex: true, editable: true, valueGetter: (value, row) => `${row.customer.title || ''}` },
        { field: 'vehicle', headerName: 'Veículo', flex: true, editable: true, valueGetter: (value, row) => `${row.vehicle.name || ''}` },
        {
            field: 'tier',
            headerName: 'Tier',
            flex: true,
            editable: true,
            valueGetter: (value, row) => `${row.value || ''}`
        },
        {
            field: 'edit',
            headerName: 'Ações',
            width: 100,
            renderCell: (params) => (
                <>
                    <IconButton
                        color="tertiary"
                        onClick={() => { openEditionModal(params) }}
                    >
                        <EditIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    const paginationModel = { page: 0, pageSize: 10 };

    const openEditionModal = (params) => {
        setSelectedTier(params["row"])
        setEditionModalOpen(true)
    }

    const closeEditionModal = () => {
        setSelectedTier({})
        setEditionModalOpen(false)
    }

    const handleSimpleChange = (event) => {
        const value = event.target.value
        setSelectedClient(value)
    }

    const fetchData = () => {
        if (selectedClient) {
            getTiersByCustomer(selectedClient).then(({ data }) => setTiers(data)).catch(err => console.log(err))
        } else {
            getTiers().then(({ data }) => setTiers(data)).catch(err => console.log(err))
        }
    }

    const showAlert = (message, type) => {
        setAlertData({ message, type })
        setOpenAlert(true)
    }

    const handleCloseAlert = () => {
        setOpenAlert(false)
    }

    useEffect(() => {
        getTiersByCustomer(selectedClient).then(({ data }) => setTiers(data)).catch(err => console.log(err))
    }, [selectedClient])

    useEffect(() => {
        fetchData()
        getCustomers().then(({ data }) => {
            setClients(data.map(({ id, title }) => ({ label: title, value: id })))
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <MainLayout>
            <Grid container direction="column">
                <Box sx={{ width: "300px" }}>
                    <Select label={"Cliente"} selectedValue={selectedClient} onChange={handleSimpleChange} options={clients} sx={{ margin: 0 }} />
                </Box>
                <Box mb={2} />
                <Paper sx={{ height: "fit-content", width: '100%' }}>
                    <DataGrid
                        rows={tiers}
                        columns={columns}
                        initialState={{ pagination: { paginationModel } }}
                        pageSizeOptions={[5, 10]}
                        autoHeight={true}
                        sx={{ border: 0 }}
                    />
                </Paper>
                <EditionModal open={editionModalOpen} close={closeEditionModal} selectedTier={selectedTier} refreshData={fetchData} showAlert={showAlert} />
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Alert
                        onClose={handleCloseAlert}
                        severity={alertData.type}
                        variant="filled"
                    >
                        {alertData.message}
                    </Alert>
                </Snackbar>
            </Grid>
        </MainLayout>
    )
}

export default TiersPage