import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const generateReport = async (startDate, endDate, customerId, language) => {
    return await axiosInstance.post(`/report`, { start_date: startDate, end_date: endDate, customer_id: customerId, language }, {
        responseType: 'blob'
    })
}

export const getVehicles = async () => {
    return await axiosInstance.get(`/vehicles`)
}

export const updateVehicle = async (id, updatedData) => {
    return await axiosInstance.put(`/vehicles/${id}`, { ...updatedData })
}

export const getCustomers = async () => {
    return await axiosInstance.get('/customers')
}

export const getTiers = async () => {
    return await axiosInstance.get('/tiers')
}

export const getTiersByCustomer = async (customerId) => {
    return await axiosInstance.get(`/tiers/${customerId}`)
}

export const updateTier = async (id, updatedData) => {
    return await axiosInstance.put(`/tiers/${id}`, { ...updatedData })
}