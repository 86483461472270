import { useEffect, useState } from "react"
import { Alert, Box, Button, CircularProgress, Grid, IconButton, Paper, Typography, Snackbar, Skeleton } from "@mui/material"
import Divider from "../../components/Divider"
import Select from "../../components/Select"
import { parseFilename } from "../../utils/parseFilename"
import DateRangePicker from "../../components/DateRangePicker"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateReport } from "../../utils/api"
import { format } from 'date-fns';
import MainLayout from "../../layouts"
import { getCustomers } from "../../utils/api"
import './results.scss'

const ResultsPageSkeleton = () => {
    return (
        <>
            <Grid container justifyContent="center" style={{ padding: "32px 0" }}>
                <Typography variant="h6"><Skeleton width={100} /></Typography>
                <Skeleton width={300} height={100} />
                <Skeleton width={300} height={100} />
                <Skeleton width={300} height={100} />
            </Grid>
        </>
    )
}

function ResultsPage() {
    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState("")
    const [languages, setLanguages] = useState([{ label: 'Português 🇧🇷', value: "pt-br" }, { label: 'Inglês 🇺🇸', value: "en" }, { label: 'Espanhol 🇪🇸', value: "es" },])
    const [selectedLanguage, setSelectedLanguage] = useState("pt-br")
    const [isLoading, setIsLoading] = useState(false)
    const [isProcessingFile, setIsProcessingFile] = useState(false)
    const [processedFileName, setProcessedFileName] = useState("")
    const [responseFileURL, setResponseFileURL] = useState()
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [alertMessage, setAlertMessage] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("null")

    const openAlert = !!alertMessage
    const handleAlertClose = () => {
        setAlertMessage("")
        setAlertSeverity("")
    }

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleClientChange = (event) => {
        const value = event.target.value
        setSelectedClient(value)
    }

    const handleLanguageChange = (event) => {
        const value = event.target.value
        setSelectedLanguage(value)
    }

    const handleSubmit = () => {
        setIsProcessingFile(true)
        const formattedStartDate = `${format(startDate, 'yyyy-MM-dd')}T00:00:00`
        const formattedEndDate = `${format(endDate, 'yyyy-MM-dd')}T23:59:59`

        generateReport(formattedStartDate, formattedEndDate, selectedClient, selectedLanguage).then(response => {
            const file = new Blob([response.data], { type: response.data.type });
            const fileURL = URL.createObjectURL(file);
            setProcessedFileName(parseFilename(response.headers['content-disposition']))
            setResponseFileURL(fileURL)
            setIsProcessingFile(false)
        }
        ).catch(err => {
            if (err.response.status === 404) {
                setAlertSeverity('error')
                setAlertMessage('Não foram encontrados dados para esse período.')
            }
            setIsProcessingFile(false)
        })

    }

    const handlePrev = () => {
        setProcessedFileName("")
        setResponseFileURL("")
    }

    useEffect(() => {
        setIsLoading(true)
        getCustomers().then(({ data }) => {
            setIsLoading(false)
            setClients(data.map(({ id, title }) => ({ label: title, value: id })))
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }, [])

    return (
        <MainLayout>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <Grid container style={{ width: "348px", marginBottom: "10px" }} justifyContent="space-evenly">
                    <img src="/cisp1.svg" style={{ width: "100px" }} />
                    <img src="/sing.png" style={{ width: "100px" }} />
                </Grid>
                <Paper elevation={0} square={false} style={{ width: '348px' }}>
                    {isLoading ? <ResultsPageSkeleton />
                        :

                        processedFileName ?
                            < Box sx={{ padding: '16px' }}>
                                <Typography variant="h6" style={{ textAlign: "center" }}>Arquivo Processado</Typography>
                                <Box py={1} />
                                <Divider />
                                <Box py={1} />
                                <Grid container justifyContent="center" direction="column">
                                    <IconButton
                                        component="label"
                                        className="file-button"
                                    >
                                        <a href={responseFileURL} download={'results.xlsx'}>
                                            <FileDownloadIcon style={{ fontSize: "100px", color: '#1976d2' }} />
                                        </a>
                                    </IconButton>
                                    <Typography variant="body1" style={{ textAlign: "center" }}>{processedFileName}</Typography>
                                    <Button variant="contained" onClick={handlePrev} style={{
                                        width: "300px",
                                        marginLeft: "8px",
                                        marginTop: "16px",
                                        height: "56px"
                                    }}>Voltar</Button>
                                </Grid>
                            </Box>
                            :

                            <Box sx={{ padding: '16px' }}>
                                <Typography variant="h6" style={{ textAlign: "center" }}>Gerar Relatório</Typography>
                                <Box py={1} />
                                <Box sx={{ margin: "8px" }}>
                                    <Select label={"Cliente"} selectedValue={selectedClient} onChange={handleClientChange} options={clients} />
                                </Box>
                                <Box pt={1} />
                                <Box sx={{ margin: "8px" }}>
                                    <Select label={"Idioma de tradução"} selectedValue={selectedLanguage} onChange={handleLanguageChange} options={languages} />
                                </Box>
                                <Box pt={1} />
                                <Grid container sx={{ position: "relative" }}>
                                    <Typography sx={{ position: 'absolute', zIndex: 1000, left: '20px', background: 'white', fontSize: '12px', color: '#777777', letterSpacing: '0.00938em', padding: '0 6px' }}>Período</Typography>
                                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={onDateChange} />
                                </Grid>
                                <Box pt={1} />
                                <Button variant="contained" onClick={handleSubmit} disabled={!selectedClient || isProcessingFile} style={{
                                    width: "300px",
                                    marginLeft: "8px",
                                    marginTop: "16px",
                                    height: "56px"
                                }}>{isProcessingFile ? <CircularProgress style={{ color: "white" }} /> : "Gerar"}</Button>
                            </Box>
                    }

                </Paper>
            </Grid>
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert
                    severity={alertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </MainLayout >
    )
}

export default ResultsPage