import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import VehiclesPage from './pages/Vehicles';
import ResultsPage from './pages/Results';
import TiersPage from './pages/Tiers';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' Component={ResultsPage} />
        <Route path='/vehicles' Component={VehiclesPage} />
        <Route path='/tiers' Component={TiersPage} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
